.policy-div {
    padding: 100px;
}

h1, h2, h3 {
    margin: 20px;
}

p {
    margin: 10px 20px;
}

ul {
    margin: 0px 50px;
}

@media screen and (max-width: 768px) {
    .policy-div {
        padding: 80px 35px;
    }

    h1, h2, h3 {
        margin: 10px;
    }

    p {
        margin: 5px 10px;
    }

    ul {
        margin: 0px 30px;
    }
}